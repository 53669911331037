import * as Sentry from "@sentry/node";
// sentry
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

import Head from "next/head";
import "./styles.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  fal,
  faArrowRight,
  faArrowLeft,
  faShare,
} from "@fortawesome/pro-light-svg-icons";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/captioned.css";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";

const DISABLE_SEO = Boolean(
  process.env.DISABLE_SEO ||
    (process.env.AWS_LAMBDA_FUNCTION_NAME &&
      process.env.AWS_LAMBDA_FUNCTION_NAME.includes("dev"))
);

library.add(fas, fab, fal, faArrowRight, faArrowLeft, faShare);

export default function ({ Component, pageProps, err }) {
  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      window.scroll({
        top: 0,
        left: 0,
      });
    });
  }, []);
  return (
    <>
      <Head>
        <title>The Haute Take Hijab Fashion Blog</title>
        <meta httpEquiv="Content-Language" content="en_US"></meta>
        <meta
          name="description"
          content="The Haute Take fashion blog is a leading destination for hijabis. Discover the latest hijab styles, stories and hijab quotes for the modern Muslim woman."
        />
        <meta property="fb:app_id" content="184298615962647" />
        <meta
          name="google-site-verification"
          content="AfSqFcnQVzxOq1yvCpSrE0SH7u5c4oQLlLlgI0wsVQ4"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var t=analytics.methods[e];analytics[t]=analytics.factory(t)}analytics.load=function(e,t){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+e+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=t};analytics.SNIPPET_VERSION="4.1.0";
                        analytics.load("gwHFBJNjywBYMgGNh4zUbOiQ46MbmrjG");
                        analytics.page();
                        }}();
                     `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                        !function(e,t,i){e.thoughtmetricQueue=[],e.thoughtmetric=function(){e.thoughtmetricQueue.push(arguments)};var c=t.createElement("script");
                        c.async=1,c.src="https://pixel.thoughtmetric.io/tmpixel.min.js?t="+864e5*Math.ceil(new Date/864e5);var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(c,n)}(window,document); 
                        thoughtmetric("init","ID-4a86b76bf3652f72"); thoughtmetric("pageview");`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: ` var _dcq = _dcq || [];
          var _dcs = _dcs || {};          
          _dcs.account = '6106886';
        `,
          }}
        />
        <script src="https://tag.getdrip.com/6106886.js" />
      </Head>
      <DefaultSeo
        dangerouslyDisableGooglebot={DISABLE_SEO}
        dangerouslySetAllPagesToNoFollow={DISABLE_SEO}
        dangerouslySetAllPagesToNoIndex={DISABLE_SEO}
        description={
          "The Haute Take fashion blog is a leading destination for hijabis. Discover the latest hijab styles, stories and hijab quotes for the modern Muslim woman."
        }
      />
      <div className="fade-in">
        <Component err={err} {...pageProps} />
      </div>
    </>
  );
}
